<template>
  <a
    aria-label="Contáctanos por WhatsApp!"
    href="https://wa.me/56966540811"
    target="_blank"
  >
    <picture>
      <source srcset="/assets/images/WhatsappIcon.webp" type="image/webp" />
      <source srcset="/assets/images/WhatsappIcon.png" type="image/png" />
      <img
        class="w-12 h-12 md:hover:scale-110 transition-all duration-300 fixed bottom-1 right-1 z-10 md:h-14 md:w-14 lg:h-16 lg:w-16 sm:bottom-4 sm:right-4"
        :class="{
          'md:!mb-0 !mb-16': ruta == 'producto',
          'md:!mb-0 !mb-12': ruta == '',
        }"
        alt="Contáctanos por WhatsApp!"
      />
    </picture>
  </a>
</template>

<script setup lang="ts">
  const ruta = document.location.pathname.split("/")[1];
</script>
